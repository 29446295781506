/* assets/styles/global.css */

.header {
    position: fixed;
    z-index: 1;
    width: 100%;
}

.site-layout-content {
    background: #fff;
    padding: 24px;
    min-height: 380px;
}

.logo {
    width: 120px;
    height: 31px;
    background: rgba(255, 255, 255, 0.3);
    margin: 16px 28px 16px 0;
    float: left;
}